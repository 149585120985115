import "./index.style.scss";
import "modern-normalize/modern-normalize.css";
import { CrashApiService } from "./services/crash/crash-api.service";
import { CrashService } from "./services/crash/crash.service";
import { FinanceService, SessionService } from "@tgg/shared";
import App from "./app";
import React from "react";
import ReactDOM from "react-dom";

// TODO: Move to config
const baseUri = "";
const crashService = new CrashService(`${baseUri}/hubs/Crash`);
const crashApiService = new CrashApiService(`${baseUri}/api/crash`);
const sessionService = new SessionService(`${baseUri}/api`);
const financeService = new FinanceService(`${baseUri}/hubs/finance`);

ReactDOM.render(
    <React.StrictMode>
        <App
            sessionService={sessionService}
            crashService={crashService}
            crashApiService={crashApiService}
            financeService={financeService}
        />
    </React.StrictMode>,
    document.getElementById("root")
);
