import "./crash-bet-placement.style.scss";
import { Button } from "../buttons/button.component";
import { CrashContext, CrashContextValue } from "../../contexts/crash.context";
import { MaxMultiply, MinMultiply } from "../../constants/crash.constant";
import { ModalContext } from "../../contexts/modal.context";
import { TextInput } from "../textinput/textinput.component";
import classNames from "classnames";
import React from "react";

export class CrashBetPlacement extends React.PureComponent<{
    joinMatch: (matchId: number, amount: number, multiplier?: number) => void;
    cashoutMatch: (matchId: number) => void;
    status: CrashContextValue["status"];
}, {
    amount?: number;
    multiplier?: number;
    highlight: boolean;
    prebet?: {
        matchId: number;
        amount: number;
        multiplier?: number;
    };
}> {
    public constructor(props: CrashBetPlacement["props"]) {
        super(props);
        this.state = {
            highlight: false,
        };
    }

    public componentDidUpdate(oldProps: CrashBetPlacement["props"]): void {
        if (oldProps.status !== this.props.status && this.props.status === "waiting" && this.state.prebet != null) {
            const prebet = this.state.prebet;
            this.setState(
                {
                    prebet: undefined,
                },
                () => {
                    this.props.joinMatch(prebet.matchId, prebet.amount, prebet.multiplier);
                }
            );
        }
    }

    public render(): JSX.Element {
        return <CrashContext.Consumer>
            {
                (context) => {
                    const isMatchInBetting = this.props.status === "waiting";
                    const isMatchIsRunning = this.props.status === "started";
                    const hasJoined = context.participant != null;
                    const hasPrebet = !!this.state.prebet;
                    const hasCachedOut = hasJoined && !!context.participant?.cashOut;
                    const canCacheOut = hasJoined && !hasCachedOut && isMatchIsRunning;
                    const canPrebet = hasJoined === hasCachedOut && !isMatchInBetting && !hasPrebet;
                    let bettingDisabledMessage = "";

                    if (!!hasJoined) {
                        if (!isMatchIsRunning) {
                            // eslint-disable-next-line max-len
                            bettingDisabledMessage = "You have already placed a bet for this round.\r\nIf you want to place a bet for the next round you need to wait for the round to begin after the cooldown period.";
                        } else if (hasPrebet) {
                            bettingDisabledMessage = "You have already placed a bet for the next round.";
                        }
                    } else {
                        if (hasPrebet) {
                            bettingDisabledMessage = "You have already placed a bet for the next round.";
                        } else if (!this.state.amount) {
                            bettingDisabledMessage = "You need to enter a bet amount before trying to place your bet.";
                        } else if (this.state.amount < context.minDeposit) {
                            bettingDisabledMessage = "The entered bet amount is too low.";
                        } else if (this.state.amount > context.maxDeposit) {
                            bettingDisabledMessage = "The entered bet amount is too high.";
                        } else if (this.state.amount > (context.balance ?? 0)) {
                            bettingDisabledMessage = "The entered bet amount is higher than your current balance.";
                        }
                    }

                    const isDisabled = context.balance == null;
                    const amount = this.state.amount;

                    return <section
                        className={
                            classNames(
                                "crashBetPlacement",
                                {
                                    highlight: this.state.highlight,
                                }
                            )
                        }
                    >
                        <section className="amount">
                            <TextInput
                                value={amount?.toFixed(2)}
                                onChanged={(v) => this.onAmountChanged(v, context)}
                                placeholder="Bet Amount..."
                                type="number"
                                isDisabled={isDisabled}
                                prefix={context.currency}
                                isClearable
                            />
                        </section>
                        <section className="add">
                            <Button
                                caption={`${context.currency ?? ""} 2`}
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged("2", context)}
                                isDisabled={isDisabled || (context.balance ?? 0) < 2}
                                hasSound={context.configuration?.isMuted === false}
                            />
                            <Button
                                caption={`${context.currency ?? ""} 5`}
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged("5", context)}
                                isDisabled={isDisabled || (context.balance ?? 0) < 5}
                                hasSound={context.configuration?.isMuted === false}
                            />
                        </section>
                        <section className="multiply">
                            <Button
                                caption={`${context.currency ?? ""} 10`}
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged("10", context)}
                                isDisabled={isDisabled || (context.balance ?? 0) < 10}
                                hasSound={context.configuration?.isMuted === false}
                            />
                            <Button
                                caption={`${context.currency ?? ""} 20`}
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged("20", context)}
                                isDisabled={isDisabled || (context.balance ?? 0) < 20}
                                hasSound={context.configuration?.isMuted === false}
                            />
                        </section>
                        <section className="minmax">
                            <Button
                                caption="MIN"
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged(Math.min(context.balance ?? 0, context.minDeposit).toFixed(2), context)}
                                hasSound={context.configuration?.isMuted === false}
                                isDisabled={isDisabled}
                            />
                            <Button
                                caption="MAX"
                                size="medium"
                                color="blue"
                                onClick={() => void this.onAmountChanged(Math.min(context.balance ?? 0, context.maxDeposit).toFixed(2), context)}
                                hasSound={context.configuration?.isMuted === false}
                                isDisabled={isDisabled}
                            />
                        </section>
                        <section className="multiplier">
                            <TextInput
                                className="multiplyInput"
                                value={this.state.multiplier?.toFixed(2)}
                                onChanged={this.onMultiplierChanged}
                                placeholder="Auto cashout..."
                                type="number"
                                isDisabled={isDisabled}
                                prefix="x"
                                isClearable
                            />
                        </section>
                        <section className="submit">
                            <ModalContext.Consumer>
                                {
                                    (modalContext) => <Button
                                        caption={canCacheOut ? "CASH-OUT" : "PLACE BET"}
                                        size="medium"
                                        color="green"
                                        isDisabled={!!bettingDisabledMessage && (hasPrebet || hasJoined)}
                                        onClick={
                                            () => !!bettingDisabledMessage ?
                                                modalContext.openModal("message", { text: bettingDisabledMessage }) :
                                                (canCacheOut ? this.onCashout(context) : (canPrebet ? this.onPrebet(context) : this.onJoin(context)))
                                        }
                                        hasSound={context.configuration?.isMuted === false}
                                        className={
                                            classNames(
                                                {
                                                    ["attention"]: canCacheOut && !bettingDisabledMessage,
                                                }
                                            )
                                        }
                                    />
                                }
                            </ModalContext.Consumer>
                        </section>
                    </section>;
                }
            }
        </CrashContext.Consumer>;
    }

    private onCashout = (context: CrashContextValue) => {
        if (
            this.props.cashoutMatch == null ||
            context.currentMatch == null ||
            context.participant == null ||
            context.status !== "started"
        ) {
            return;
        }

        this.props.cashoutMatch(context.currentMatch.id);
    };

    private onJoin = (context: CrashContextValue) => {
        if (
            this.props.joinMatch == null ||
            this.state.amount == null ||
            context.currentMatch == null ||
            context.participant != null ||
            context.status === "started"
        ) {
            return;
        }

        this.setState({ highlight: true });
        setTimeout(
            () => {
                this.setState({ highlight: false });
            },
            750
        );

        this.props.joinMatch(context.currentMatch.id, this.state.amount, this.state.multiplier);
    };

    private onPrebet = (context: CrashContextValue) => {
        if (
            this.props.joinMatch == null ||
            this.state.amount == null ||
            context.currentMatch == null
        ) {
            return;
        }

        this.setState(
            {
                highlight: true,
                prebet: {
                    amount: this.state.amount,
                    multiplier: this.state.multiplier,
                    matchId: context.status === "started" ? context.currentMatch.id + 1 : context.currentMatch.id,
                },
            }
        );

        setTimeout(
            () => {
                this.setState({ highlight: false });
            },
            750
        );
    };

    private onMultiplierChanged = (value: string) => {
        // allow clear
        if (value === "") {
            this.setState(
                {
                    multiplier: undefined,
                }
            );
            return true;
        }

        let multiplier: number = parseFloat(value);
        if (isNaN(multiplier) || !isFinite(multiplier)) {
            return false;
        }

        multiplier = parseFloat(multiplier.toFixed(2));
        this.setState(
            {
                multiplier,
            },
            () => {
                this.setState(
                    {
                        multiplier: Math.min(Math.max(multiplier, MinMultiply), MaxMultiply),
                    }
                );
            }
        );

        return true;
    };

    // private onAmountAdd = (add: number) => {
    //     this.setState(
    //         {
    //             amount: Math.min((this.state.amount ?? 0) + add, MaxAmount),
    //         }
    //     );
    // };

    // private onAmountMultiply = (multiplier: number) => {
    //     if (!this.state.amount) {
    //         return;
    //     }

    //     this.setState(
    //         {
    //             amount: Math.max(Math.min(this.state.amount * multiplier, MaxAmount), MinAmount),
    //         }
    //     );
    // };

    private onAmountChanged(value: string, context: CrashContextValue): boolean {
        // allow clear
        if (value === "") {
            this.setState(
                {
                    amount: undefined,
                }
            );
        }

        let amount = parseFloat(value);
        if (isNaN(amount) || !isFinite(amount)) {
            return false;
        }

        amount = parseFloat(amount.toFixed(2));
        this.setState(
            {
                amount,
            },
            () => {
                this.setState(
                    {
                        amount: Math.min(Math.max(amount, context.minDeposit), context.maxDeposit),
                    }
                );
            }
        );

        return true;
    }
}
